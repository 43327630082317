<template>
  <div>
    <!-- Quick tour -->
    <quick-tour-card-wrapper
      :active="quickTourVisible"
      closable
      @close="onCloseQuickTour"
    >
      <financial-plan-quick-tour />
    </quick-tour-card-wrapper>
    <!-- /Quick tour -->

    <div v-if="isPageContentVisible" class="d-flex justify-content-end mb-3">

      <template v-if="canEdit && profitAndCashFlowHasItemsFromTemplate">
        <!-- Delete handwriting -->
        <!--        <div-->
        <!--          v-if="isIdeaFromTemplate && !isProfitAndCashFlowEmpty && !isOverviewEverUpdated"-->
        <!--          class="d-none d-md-flex"-->
        <!--        >-->
        <!--          <financial-plan-handwriting :text="$t('handwriting.clickHereToDeleteAllTemplateItems')" />-->
        <!--        </div>-->
        <!-- /Delete handwriting -->

        <erase-icon-button
          v-if="isIdeaFromTemplate"
          class="ml-3"
          @click="onDelete"
        />
      </template>

      <!-- Add handwriting -->
      <!--      <div-->
      <!--        v-if="canEdit && !isIdeaFromTemplate && isProfitAndCashFlowEmpty"-->
      <!--        class="d-none d-md-flex"-->
      <!--      >-->
      <!--        <financial-plan-handwriting :text="$t('handwriting.addARevenueStreamOrACostItem')" />-->
      <!--      </div>-->
      <!-- /Add handwriting -->

      <!-- Add New / Edit Dropdown -->
      <!--      <div class="d-block">-->
      <!--        <add-new-dropdown-->
      <!--          :options="addNewDropdownOptions"-->
      <!--          :disabled="!canEdit"-->
      <!--          @loading="loading = $event.value"-->
      <!--        />-->
      <!--      </div>-->
      <!-- /Add New / Edit Dropdown -->
    </div>
    <loader v-if="loading" />
    <template v-if="isPageContentVisible && !loading">

      <overview-summary class="mb-4" />

      <template v-if="!isProfitAndCashFlowEmpty">
        <ib-card :animation="false" class="mb-4 pt-4 pb-1">
          <profit-and-loss-projection />
        </ib-card>
        <ib-card :animation="false" class="mb-4 pt-4 pb-1">
          <cash-flow-projection />
        </ib-card>

        <template v-if="showTables">
          <ib-card :animation="false" class="mb-4 pt-4 pb-1">
            <revenue-over-time />
          </ib-card>
        </template>

        <template v-if="showTables">
          <ib-card :animation="false" class="mb-4 pt-4 pb-2">
            <timeline-of-total-costs />
          </ib-card>
        </template>
      </template>
      <template v-if="isProfitAndCashFlowEmpty">
        <empty-placeholder
          :images="[
            require('@/assets/img/placeholders/timeline-graph-fp-overview.svg'),
            require('@/assets/img/placeholders/graph-dummy-fp-overview.svg'),
            require('@/assets/img/placeholders/table-graph-fp-overview.svg')
          ]"
        />
      </template>
    </template>
  </div>
</template>

<script>
// import AddNewDropdown from '@/views/Home/StoryMode/Components/AddNewDropdown'
import CashFlowProjection from '@/views/Home/StoryMode/FinancialPlan/Components/Overview/CashFlowProjection'
import EmptyPlaceholder from '@/views/Home/StoryMode/FinancialPlan/Components/EmptyPlaceholder'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import EventBus from '@/event-bus'
// import FinancialPlanHandwriting from '@/views/Home/StoryMode/FinancialPlan/Components/FinancialPlanHandwriting'
import FinancialPlanQuickTour from '@/views/Home/Components/QuickTours/FinancialPlanQuickTour'
import Loader from '@/components/Loader'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinTable from '@/mixins/table'
import OverviewSummary from '@/views/Home/StoryMode/FinancialPlan/Summary/Summary'
import ProfitAndLossProjection from '@/views/Home/StoryMode/FinancialPlan/Components/Overview/ProfitAndLossProjection'
import QuickTourCardWrapper from '@/views/Home/Components/QuickTours/QuickTourCardWrapper'
import addNewDropdownOptions from '@/data/financialPlanOverviewAddNewDropdownOptions'

import { mapGetters, mapState } from 'vuex'
import RevenueOverTime from '@/views/Home/StoryMode/FinancialPlan/Components/Revenue/RevenueOverTime.vue'
import TimelineOfTotalCosts
  from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/TotalCost/TimelineOfTotalCosts.vue'

export default {
  name: 'Overview',

  components: {
    TimelineOfTotalCosts,
    RevenueOverTime,
    // AddNewDropdown,
    CashFlowProjection,
    EmptyPlaceholder,
    EraseIconButton,
    // FinancialPlanHandwriting,
    FinancialPlanQuickTour,
    Loader,
    OverviewSummary,
    ProfitAndLossProjection,
    QuickTourCardWrapper
  },

  mixins: [
    MixinApiCalculations,
    MixinDeleteIdeaExamples,
    MixinGuide,
    MixinIdeaRoles,
    MixinTable
  ],

  data () {
    return {
      loading: false,
      showTables: false,
      quickTourVisible: false,
      isPageContentVisible: true,
      addNewDropdownOptions
    }
  },

  computed: {
    ...mapState(['idea']),
    ...mapGetters('idea', ['isOverviewEverUpdated']),

    isProfitAndCashFlowEmpty () {
      return !(
        this.idea.storyMode.forecast.revenueStreams.revenueStreams.length !== 0 ||
          this.idea.storyMode.forecast.directCosts.directCosts.length !== 0 ||
          this.idea.storyMode.setUp.staffAndPayrolls.staffAndPayrolls.length !== 0 ||
          this.idea.storyMode.forecast.marketingBudget.marketingElements.length !== 0 ||
          this.idea.storyMode.forecast.otherOverheads.otherOverheads.length !== 0 ||
          this.idea.storyMode.setUp.startupCosts.startupCosts.length !== 0 ||
          this.idea.storyMode.setUp.financing.finances.length !== 0
      )
    }
  },

  watch: {
    'quickTourVisible' (active) {
      if (active) {
        EventBus.$emit('open-quick-tour')
      }
    }
  },

  created () {
    this.openQuickTour('financialPlanQuickTour')
  },

  mounted () {
    EventBus.$on('toggle-quick-tour', (payLoad) => {
      this.quickTourVisible = payLoad.active
      this.isPageContentVisible = true
    })

    EventBus.$on('remove-examples', () => {
      this.deleteExamples()
    })
  },

  methods: {
    onCloseQuickTour () {
      this.quickTourVisible = false
      this.isPageContentVisible = true
      EventBus.$emit('close-quick-tour')
    },

    deleteExamples () {
      this.deleteProfitAndCashFlowExamplesMixin()
        .then(_ => {
          this.loading = true
          this.getAllCalculations()
            .then(() => {
              this.loading = false
            })
            .finally(() => {
              this.loading = false
            })
        })
    }
  }
}
</script>
